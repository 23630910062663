import * as React from "react";
import { Helmet } from "react-helmet";

import BedJump from "../images/bed-jump-md.jpg";
import Header from "../templates/header";
import Footer from "../templates/footer";
import CallToAction from "../components/call-to-action";
import Freedom from "../components/freedom";

import Modal from "react-modal";
import ReactPlayer from "react-player/lazy";

import "./video-player.css";

// markup
const HowClotoWorks = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);

  // https://stackoverflow.com/questions/52879362/gatsby-open-modal-window-on-button-click
  Modal.setAppElement("#___gatsby");

  const watchVideo = (e) => {
    setIsOpen(true);
  };

  function afterOpenModal() {
    setPlaying(true);
  }

  function closeModal() {
    setPlaying(false);
    setIsOpen(false);
  }

  return (
    <div>
      <Helmet>
        <title>How Cloto Works</title>
        <meta name="description" content="Using Cloto is easy. Download our app and use it to schedule a pickup. Put your items in the tote and leave it outside the front door on your pickup day. We'll pick it up and return it to you within two days, washed and folded."/>
      </Helmet>
      <Header selected={1}></Header>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        shouldCloseOnEsc={true}
        contentLabel="Laundry Day Blues?"
        className="video-modal"
        overlayClassName="video-modal-overlay"
      >
        <ReactPlayer
          url="https://vimeo.com/503762662"
          width="100%"
          height="100%"
          playing={playing}
        />
      </Modal>

      <div className="relative bg-gray-800">
        <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <img className="w-full h-full object-cover" src={BedJump} alt="" />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
          <div className="md:ml-auto md:w-1/2 md:pl-10">
            <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
              Reimagine Laundry
            </h2>
            <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
              How Cloto Works
            </p>
            <p className="mt-3 text-lg text-gray-300">
              Download our app and use it to schedule a pickup. Put your items
              in the tote and leave it outside the front door on your pickup
              day. We'll pick it up and return it to you within two days, washed
              and folded.
            </p>
            <p className="mt-3 text-lg text-gray-300">Relax and repeat.</p>
            <div className="mt-8">
              <div className="inline-flex rounded-md shadow">
                <button
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                  onClick={(e) => watchVideo()}
                >
                  Watch the Video
                  <svg
                    className="-mr-1 ml-3 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Freedom></Freedom>
      <CallToAction />
      <Footer></Footer>
    </div>
  );
};

export default HowClotoWorks;
