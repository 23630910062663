import * as React from "react";

import WorkIcon from "../images/cloto-sm.png";
import StepOne from "../images/step-1-sm.jpg";
import StepTwo from "../images/step-2-sm.jpg";
import StepThree from "../images/step-3-sm.jpg";
import StepFour from "../images/step-4-sm.jpg";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./freedom.css";

// markup
const Freedom = () => {
  return (
    <div>
      <h1 className="mb-8 mt-8 text-4xl font-bold text-center">
        Steps to Laundry Freedom
      </h1>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#1e4b6d", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid #1e4b6d" }}
          iconStyle={{ background: "#1e4b6d", color: "#fff" }}
          icon={<img src={WorkIcon} className="pin-icon" alt="" />}
        >
          <img src={StepOne} className="mb-4" alt="Download the app"></img>
          <h3 className="vertical-timeline-element-title font-semibold text-xl">
            Easy to Use
          </h3>
          <p>
            Download the app and create an account. Subscribe to a monthly plan,
            or try our pay-per-use option. Use the app to place an order, track
            your Clo-Totes or Clo-Bags and share with friends.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#1e4b6d", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid #1e4b6d" }}
          iconStyle={{ background: "#1e4b6d", color: "#fff" }}
          icon={<img src={WorkIcon} className="pin-icon" alt="" />}
        >
          <img src={StepTwo} className="mb-4" alt="Schedule your totes"></img>
          <h3 className="vertical-timeline-element-title font-semibold text-xl">
            There for You
          </h3>
          <p>
            Schedule the number of Clo-Totes or Clo-Bags you need to send out.
            We’ll collect them once a week on your area’s laundry day. You can
            schedule pickups up to four weeks in advance.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#1e4b6d", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid #1e4b6d" }}
          iconStyle={{ background: "#1e4b6d", color: "#fff" }}
          icon={<img src={WorkIcon} className="pin-icon" alt="" />}
        >
          <img src={StepThree} className="mb-4" alt="Fill your totes"></img>
          <h3 className="vertical-timeline-element-title font-semibold text-xl">
            Drop It In
          </h3>
          <p>
            Fill the Clo-Totes or Clo-Bags with your dirty laundry or dry
            cleaning – we’ll take it from there! All you have to do is remove
            anything not intended for wash (like gum, poop, or headphones) and
            pre-treat stubborn stains.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#1e4b6d", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid #1e4b6d" }}
          iconStyle={{ background: "#1e4b6d", color: "#fff" }}
          icon={<img src={WorkIcon} className="pin-icon" alt="" />}
        >
          <img
            src={StepFour}
            className="mb-4"
            alt="Place on your doorstep"
          ></img>
          <h3 className="vertical-timeline-element-title font-semibold text-xl">
            Set it and Forget it
          </h3>
          <p>
            Place the Clo-Totes or Clo-Bags on your doorstep. Your laundry
            picked up and delivered back to you – fresh and clean – in 2 days.
            Just sit back, relax, and enjoy your life.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
};

export default Freedom;
